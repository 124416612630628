<template>
  <div>
    <el-table
      :data="profitRateConfig.data"
      style="width: 100%">
      <el-table-column
        align="center"
        label="价格范围">
        <template slot-scope="scope">
          {{ scope.row.tradeMinAmount }} ~ {{ scope.row.tradeMaxAmount }} 元
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="佣金比例">
        <template slot-scope="scope">
          <template v-if="scope.row.unitflag === 1">
            {{ scope.row.tradeRate }} %
          </template>
          <template v-else-if="scope.row.unitflag === 2">
            {{ scope.row.tradeRate }} 元
          </template>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {common} from "@/apis/index";

export default {
  name: "ProfitRate",
  data() {
    return {
      profitRateConfig: {
        data: null,
        loadData: () => {
          common.profitRateList()
            .then(res => {
              let {retdata} = res;
              this.profitRateConfig.data = retdata;
            })
        }
      }
    }
  },
  beforeMount() {
    this.profitRateConfig.loadData();
  }
}
</script>

<style scoped lang="scss">

</style>
